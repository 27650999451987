$(document).ready(function() {

	function showHidePassword(target) {
		let input = document.getElementById('password-input');
		if (input.getAttribute('type') == 'password') {
			target.classList.add('view');
			input.setAttribute('type', 'text');
		} else {
			target.classList.remove('view');
			input.setAttribute('type', 'password');
		}
		return false;
	}
	
	window.showHidePassword = showHidePassword;

});

document.addEventListener("DOMContentLoaded", function () {
	const btn = document.querySelector('.main__btn');
	const form = document.querySelector('.form__inner');
	const formWrapper = document.querySelector('.form_wrapper');

	btn.addEventListener('click', function() {
		form.classList.add('active');
		document.body.classList.add('active');
	});
});